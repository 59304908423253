import OrderDetailsModel from '../Order/Models/OrderDetailsModel.interface';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import OrderComponent from './OrderComponent';
import OrderRow from './OrderRow';

type PropType = {
  orders: OrderDetailsModel[];
  ordersCount: number;
  isLoading: boolean;
};
function OrderHistory({ orders, ordersCount, isLoading }: PropType) {
  const {
    staticPages: { orderHistoryPage },
  } = useAppSettingsData();

  const {
    'customerInfo/myOrders/orderHistory': orderHistoryLabel,
    'customerInfo/myOrders/allOrders': allOrdersLabel,
  } = useTranslationData();

  return (
    <OrderComponent
      isLoading={isLoading}
      headingLabel={orderHistoryLabel}
      totalOrders={ordersCount}
      showAllLabel={allOrdersLabel}
      url={orderHistoryPage}
    >
      {orders.map((order: OrderDetailsModel) => (
        <OrderRow key={order.orderId} order={order} />
      ))}
    </OrderComponent>
  );
}

export default OrderHistory;
