import OrderDetailsModel from '../Order/Models/OrderDetailsModel.interface';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import OrderComponent from './OrderComponent';
import OrderRow from './OrderRow';

type PropType = {
  orders: OrderDetailsModel[];
  ordersCount: number;
  isLoading: boolean;
};

function ActiveOrders({ orders, ordersCount, isLoading }: PropType) {
  const {
    staticPages: { activeOrdersPage },
  } = useAppSettingsData();

  const {
    'customerInfo/myOrders/activeOrder': activeOrderLabel,
    'customerInfo/myOrders/allActiveOrders': allActiveOrdersLabel,
  } = useTranslationData();

  return (
    <OrderComponent
      isLoading={isLoading}
      headingLabel={activeOrderLabel}
      totalOrders={ordersCount}
      showAllLabel={allActiveOrdersLabel}
      url={activeOrdersPage}
    >
      {orders.map((order: OrderDetailsModel) => (
        <OrderRow key={order.orderId} order={order} />
      ))}
    </OrderComponent>
  );
}

export default ActiveOrders;
