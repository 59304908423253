import React from 'react';
import { ArrowIcon } from '../Atoms/Icons';
import Dots from '../Atoms/Loaders/Dots';
import { H2 } from '../Atoms/Typography/Headings/Heading';
import KexLink from '../Kex/KexLink';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { styled } from '../stitches.config';

type PropType = {
  isLoading: boolean;
  headingLabel: string;
  url: string;
  showAllLabel: string;
  totalOrders: number;
  children?: React.ReactNode;
};

function OrderComponent({
  isLoading,
  headingLabel,
  url,
  totalOrders,
  showAllLabel,
  children,
}: PropType) {
  const { 'customerInfo/myOrders/noOrders': noOrdersLabel } =
    useTranslationData();

  return (
    <>
      <OrderWrapper>
        <HeadingSection>
          <StyledH2 noMargin>
            {headingLabel} {`- ${totalOrders}`}
          </StyledH2>
        </HeadingSection>
        {children ? (
          <>
            <OrderArray>{children}</OrderArray>
          </>
        ) : !isLoading ? (
          <NoOrders>{noOrdersLabel}</NoOrders>
        ) : (
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        )}
        {children && url && totalOrders > 0 ? (
          <StyledKexLink href={url}>
            {showAllLabel}
            <StyledArrowRightLinkIcon color={'primary'} />
          </StyledKexLink>
        ) : (
          <></>
        )}
      </OrderWrapper>
    </>
  );
}

const StyledArrowRightLinkIcon = styled(ArrowIcon, {
  wh: 4,
  ml: 2,
});

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  mt: 'auto',
  ':hover': {
    textDecoration: 'underline',
  },
});

const OrderWrapper = styled('div', {
  backgroundColor: '$white',
  display: 'flex',
  flexDirection: 'column',
  p: 4,
  w: '100%',
  br: 2.5,
  '@mediaMinLarge': {
    p: 8,
  },
});

const NoOrders = styled('div', {
  my: 1,
});

const HeadingSection = styled('div', {
  '@mediaMaxLarge': { pl: 0.25 },
});

const StyledH2 = styled(H2, {
  fontWeight: '$fw300',
  fs: 13,
  lineHeight: '31px',
  mx: 0,
  mt: 0,
  mb: 4,
});

const DotsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  pb: 9,
});

const OrderArray = styled('div', {
  w: '100%',
  mb: 2.5,
});

export default OrderComponent;
