import { getFormattedDate } from '../Shared/Common/Helpers';
import OrderDetailsModel from '../Order/Models/OrderDetailsModel.interface';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';
import {
  AttentionIcon,
  DoneIcon,
  PackageIcon,
  PreviewIcon,
} from '../Atoms/Icons';
import { styled } from '../stitches.config';
import KexLink from '../Kex/KexLink';
import React, { useState } from 'react';

type PropType = {
  order: OrderDetailsModel;
};

function OrderRow({ order }: PropType) {
  const renderIcon = (status: string) => {
    switch (status) {
      case 'Completed':
        return <DoneIcon css={IconStyle} />;
      case 'Processing':
        return <PackageIcon css={IconStyle} />;
      case 'Confirmed':
        return <PreviewIcon css={IconStyle} />;
      case 'Init':
        return <AttentionIcon css={IconStyle} />;
      default:
        return null;
    }
  };

  const {
    staticPages: { orderDetailsPage },
  } = useAppSettingsData();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <>
      <ArrayItems
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <ContentWrapperLink
          href={`${orderDetailsPage}?orderId=${order.externalOrderID}`}
          noUnderline
        >
          <div>
            <IconRounded>{renderIcon(order.orderStatus)}</IconRounded>
          </div>
          <ArrayItem>
            <OrderId underline={isHovered}>{order.externalOrderID}</OrderId>
            <ArrayOrderItem>
              <Status>{order.status}</Status>
              {`- ${getFormattedDate(order.orderDate)}`}
            </ArrayOrderItem>
          </ArrayItem>
        </ContentWrapperLink>
      </ArrayItems>
    </>
  );
}

const IconStyle = {
  wh: 5,
};

const IconRounded = styled('div', {
  mr: 4,
  wh: 8,
  br: 4,
  backgroundColor: '$green',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ArrayItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pb: 4,
});

const ArrayItems = styled('div', {
  mb: 4,
  my: 5,
});

const ContentWrapperLink = styled(KexLink, {
  display: 'flex',
  alignItems: 'center',
});

const OrderId = styled('span', {
  fs: 8,
  fontWeight: '$fw700',
  lineHeight: '28px',
  variants: {
    underline: {
      true: {
        textDecoration: 'underline',
      },
    },
  },
});

const Status = styled('span', {
  fontWeight: '$fw700',
});

const ArrayOrderItem = styled('span', {
  fs: 7,
  lineHeight: '20px',
});

export default OrderRow;
