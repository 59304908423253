import { useState } from 'react';
import Address from '../../MyPagesPage/Models/Address.interface';
import CustomerInformationModel from '../../MyPagesPage/Models/CustomerInformationModel';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { ArrowIcon, SearchIcon, CloseIcon, EditIcon } from '../../Atoms/Icons/';
import Divider from '../../Atoms/Divider/Divider';

type AddressesType = 'careOf' | 'city' | 'country' | 'postalCode' | 'street';

type PropType = {
  customerInformationData?: CustomerInformationModel;
  updateMyInformation: (data: any, addressUpdate?: boolean) => any;
};

function CustomerInformationAddress({
  customerInformationData,
  updateMyInformation,
}: PropType) {
  const {
    'customerInfo/primaryAddress': primaryAddressLabel,
    'customerInfo/billingAddress': billingAddressLabel,
    'customerInfo/savedAddresses': savedAddressesLabel,
    'customerInfo/sameAsDelivery': sameAsDeliveryLabel,
    'customerInfo/expand': expandLabel,
    'customerInfo/close': closeLabel,
    'customerInfo/edit': editLabel,
    'customerInfo/cancel': cancelLabel,
    'customerInfo/address/sortSearchTitle': sortSearchTitleLabel,
    'customerInfo/address/searchPlaceholder': searchPlaceholderLabel,
    'customerInfo/address/newAddress': newAddressLabel,
    'customerInfo/address/addNewAddress': addNewAddressLabel,
    'customerInfo/address/addNewAddressShort': addNewAddressShortLabel,
    'checkoutPage/careOf': careOfLabel,
  } = useTranslationData();

  let copyData = JSON.parse(JSON.stringify(customerInformationData));

  const [customerInformationDataToEdit, setCustomerInformationDataToEdit] =
    useState<any>(copyData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState(-1);
  const [editingAddress, setEditingAddress] = useState(-1);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [newAddress, setNewAddress] = useState<Address>({} as Address);

  const resetData = (onlyAddress?: boolean, resetData?: boolean) => {
    if (resetData) {
      setCustomerInformationDataToEdit(customerInformationData);
    }

    setValidationMessage('');
    setIsLoading(false);
    setDisabled(false);
    setError(false);
    setSuccess(false);
    setBtnEnabled(false);
    setQuery('');
    setNewAddress({} as Address);

    if (onlyAddress) {
      setEditingAddress(-1);
    } else {
      setEditing(-1);
      setEditingAddress(-1);
    }
  };

  const onInputsValid = () => {
    setBtnEnabled(true);
  };

  const onInputsInvalid = () => {
    setBtnEnabled(false);
  };

  const handleSetEditing = (value: number) => {
    if (value === -1) {
      resetData();
    }
    setEditing(value);
  };

  const handleSetEditingAddress = (value: number) => {
    if (value === -1) {
      resetData(true);
    }
    setEditingAddress(value);
  };

  const handleCheckSameAsDelivery = () => {
    const data = {
      ...customerInformationDataToEdit,
    } as CustomerInformationModel;
    data.invoiceAddress.sameAsDelivery = !data.invoiceAddress.sameAsDelivery;

    if (data.invoiceAddress.sameAsDelivery) {
      setBtnEnabled(true);
    }

    setCustomerInformationDataToEdit(data);
  };

  const handleSetAddress = (value: string, name: AddressesType) => {
    const data = {
      ...customerInformationDataToEdit,
    } as CustomerInformationModel;
    data.deliveryAddress[name] = value;

    setCustomerInformationDataToEdit(data);
  };

  const handleUpdateAddresses = (
    value: string,
    name: AddressesType,
    id: string
  ) => {
    const data = {
      ...customerInformationDataToEdit,
    } as CustomerInformationModel;

    let addressToUpdate = data.optionalDeliveryAddresses.find(
      (x) => x.id === id
    );

    if (addressToUpdate) {
      addressToUpdate[name] = value;
    }

    setCustomerInformationDataToEdit(data);
  };

  const handleSelectChange = (e: any, address: Address) => {
    let data = {
      ...customerInformationDataToEdit,
    } as CustomerInformationModel;

    data.deliveryAddress = address;

    setCustomerInformationDataToEdit(data);
    handleSubmit(e, true, data);
  };

  const handleAddNewAddress = () => {
    if (Object.keys(newAddress).length !== 0) {
      setNewAddress({} as Address);
    } else {
      const totalAddresess = [
        ...customerInformationDataToEdit.optionalDeliveryAddresses,
      ].length;
      setNewAddress({
        careOf: '',
        city: '',
        country: 'SE',
        id: (totalAddresess + 1).toString(),
        postalCode: '',
        sameAsDelivery: false,
        street: '',
      } as Address);
    }
  };

  const handleUpdateNewAddress = (value: string, name: AddressesType) => {
    const newAdr = {
      ...newAddress,
    } as Address;
    newAdr[name] = value;
    setNewAddress(newAdr);
  };

  const handleSubmitNewAddress = async (e: any) => {
    const data = {
      ...customerInformationDataToEdit,
    } as CustomerInformationModel;

    data.optionalDeliveryAddresses.push(newAddress);
    setCustomerInformationDataToEdit(data);

    handleSubmit(e, true, data);
  };

  const handleSubmit = async (
    e: any,
    onlyAddress?: boolean,
    updatedData?: any
  ) => {
    if (e) e.preventDefault();
    if (disabled) return;

    const data = {
      customerInformation: updatedData || customerInformationDataToEdit,
    };
    setIsLoading(true);
    setDisabled(true);
    setError(false);
    setValidationMessage('');

    const res = await updateMyInformation(data, true);

    if (res.status !== 200) {
      setError(true);
      setValidationMessage(res.message);
    }

    setSuccess(true);
    resetData(onlyAddress);
  };

  const handleRenderListItemTitle = (
    title: string,
    editNum: number,
    expandIcon?: boolean
  ) => {
    return (
      <ListItemTitle>
        {title}
        <StyledButton
          onClick={() => handleSetEditing(editing === editNum ? -1 : editNum)}
          disabled={disabled}
        >
          {expandIcon ? (
            <>
              {editing === editNum ? closeLabel : expandLabel}
              {editing === editNum ? (
                <StyledArrowUpIcon />
              ) : (
                <StyledArrowDownIcon />
              )}
            </>
          ) : (
            <>
              {editing === editNum ? <StyledCloseIcon /> : <StyledEditIcon />}
              {editing === editNum ? cancelLabel : editLabel}
            </>
          )}
        </StyledButton>
      </ListItemTitle>
    );
  };

  const handleSearch = (e: any) => setQuery(e.currentTarget.value);

  return (
    <>
      <Item>
        {
          <ListItemSmall>
            <ListItemTitle>{primaryAddressLabel}</ListItemTitle>
            <ListItem>
              {customerInformationDataToEdit?.deliveryAddress?.street}
            </ListItem>
            <ListItem>
              {customerInformationDataToEdit?.deliveryAddress?.postalCode}{' '}
              {customerInformationDataToEdit?.deliveryAddress?.city}
            </ListItem>
          </ListItemSmall>
        }
      </Item>
      <Divider type={'max'} css={dividerStyle} />

      <Item>
        <ListItemTitle>{billingAddressLabel}</ListItemTitle>
        <ListItemSmall>
          {customerInformationDataToEdit?.invoiceAddress?.sameAsDelivery ? (
            <ListItem italic={true}>{sameAsDeliveryLabel}</ListItem>
          ) : (
            <>
              <ListItem>
                {customerInformationDataToEdit?.invoiceAddress?.street}
              </ListItem>
              <ListItem>
                {customerInformationDataToEdit?.invoiceAddress?.postalCode}{' '}
                {customerInformationDataToEdit?.invoiceAddress?.city}
              </ListItem>
            </>
          )}
        </ListItemSmall>
      </Item>

      <Divider type={'max'} css={dividerStyle} />

      <Item>
        {handleRenderListItemTitle(
          editing === 2
            ? sortSearchTitleLabel
            : `${savedAddressesLabel} - ${customerInformationDataToEdit?.optionalDeliveryAddresses?.length}`,
          2,
          true
        )}
        {editing === 2 && (
          <ListItem>
            <div>
              <SearchContainer>
                <StyledSearchIcon />
                <InputField
                  type="search"
                  placeholder={searchPlaceholderLabel}
                  onChange={handleSearch}
                />
              </SearchContainer>
              <AddressList>
                {customerInformationDataToEdit?.optionalDeliveryAddresses
                  .filter((address: Address) =>
                    address.street
                      .toLocaleLowerCase()
                      .includes(query.toLocaleLowerCase())
                  )
                  .map((address: Address, index: number) => (
                    <DeliveryAddress key={index}>
                      <Container>
                        {customerInformationData && (
                          <Bold>{customerInformationData.companyName}</Bold>
                        )}
                        {address.careOf && (
                          <span>
                            {careOfLabel} {address.careOf}
                          </span>
                        )}
                        <span>{address.street}</span>
                        <span>
                          {address.postalCode} {address.city}
                        </span>
                      </Container>
                    </DeliveryAddress>
                  ))}
              </AddressList>
            </div>
          </ListItem>
        )}
      </Item>
    </>
  );
}

const TitleStyle = {
  lineHeight: '28px',
  fs: 8,
  my: '1em',
  fontWeight: '$fw700',
  ls: '$ls125',
};

const dividerStyle = {
  mx: 0,
  backgroundColor: '$borderPrimary',
};

const ButtonStyle = {
  width: '100%',
  p: 2,
};

const DeliveryAddress = styled('div', {
  p: 4,
  br: 2.5,
  border: '1px solid $grey300',
  '&:not(:last-child)': {
    mb: 4,
  },
});

const Bold = styled('span', {
  fontWeight: '$fw700',
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  fs: 5,
  lineHeight: '16px',
  rowGap: '4px',
  '@mediaMinLarge': {
    fs: 7,
  },
});

const AddressList = styled('ul', {});

const StyledButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  p: 0,
});

const IconStyle = {
  wh: 3.5,
  mr: 1,
};

const StyledCloseIcon = styled(CloseIcon, {
  ...IconStyle,
});

const StyledEditIcon = styled(EditIcon, {
  ...IconStyle,
});

const StyledArrowUpIcon = styled(ArrowIcon, {
  ...IconStyle,
  marginRight: 0,
  marginLeft: '5px',
  transform: 'rotate(-90deg)',
});

const StyledArrowDownIcon = styled(ArrowIcon, {
  ...IconStyle,
  marginRight: 0,
  marginLeft: '5px',
  transform: 'rotate(90deg)',
});

const Item = styled('div', {
  variants: {
    flex: {
      true: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});

const ListItemStyle = {
  lineHeight: '28px',
  fs: 8,
  pb: 4,
  variants: {
    flex: {
      true: {
        ml: 2.5,
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
  },
};

const ListItemSmall = styled('div', {
  maxW: 100,
  ...ListItemStyle,
});

const ListItem = styled('div', {
  ...ListItemStyle,
});

const ListItemTitle = styled('div', {
  ...TitleStyle,
  mb: 2.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const SearchContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  w: '100%',
  borderBottom: '1px solid $grey600',
  mb: 5,
});

const StyledSearchIcon = styled(SearchIcon, {
  wh: 4,
  mr: 1,
  left: 0,
});

const InputField = styled('input', {
  backgroundColor: 'transparent',
  flexGrow: 1,
  flexShrink: 1,
  fs: 7,
  pl: 3,
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: '$grey700',
  },
});

export default CustomerInformationAddress;
