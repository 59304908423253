import { useState } from 'react';
import CustomerInformationProfileEditContent from './CustomerInformationProfileEditContent';
import UserState from '../Shared/Models/UserState.interface';
import CustomerInformationAccount from './Edit/CustomerInformationAccount';
import CustomerInformationAddress from './Edit/CustomerInformationAddress';
import UserCredentials from '../LoginPage/Models/UserCredentials.interface';
import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel';
import { styled } from '../stitches.config';
import { ArrowIcon } from '../Atoms/Icons';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';

type PropType = {
  customerInformationData?: CustomerInformationModel;
  user: UserState;
  handleToggleEditMode: () => any;
  updateMyInformation: (data: any, addressUpdate?: boolean) => any;
  handleChangePassword: (
    userCredentials: UserCredentials,
    setError: any,
    setValidationMessage: any
  ) => any;
};

function CustomerInformationProfileEdit({
  customerInformationData,
  user,
  handleToggleEditMode,
  updateMyInformation,
  handleChangePassword,
}: PropType) {
  const {
    'customerInfo/backLink': backLinkLabel,
    'customerInfo/tab/accountTabLabel': accountTabLabel,
    'customerInfo/tab/accountTitleLabel': accountTitleLabel,
    'customerInfo/tab/accountSubTitleLabel': accountSubTitleLabel,
    'customerInfo/tab/addressTabLabel': addressTabLabel,
    'customerInfo/tab/addressTitleLabel': addressTitleLabel,
    'customerInfo/tab/addressSubTitleLabel': addressSubTitleLabel,
    'customerInfo/tab/settingsTitleLabel': settingsTitleLabel,
    'customerInfo/tab/settingsSubTitleLabel': settingsSubTitleLabel,
  } = useTranslationData();

  const [activeTab, setActiveTab] = useState<number>(0);

  const renderEditItem = () => {
    switch (activeTab) {
      case 0:
        return (
          <CustomerInformationProfileEditContent
            title={accountTitleLabel}
            subTitle={accountSubTitleLabel}
          >
            <CustomerInformationAccount
              customerInformationData={customerInformationData}
              user={user}
              updateMyInformation={updateMyInformation}
              handleChangePassword={handleChangePassword}
            />
          </CustomerInformationProfileEditContent>
        );
      case 1:
        return (
          <CustomerInformationProfileEditContent
            title={addressTitleLabel}
            subTitle={addressSubTitleLabel}
          >
            <CustomerInformationAddress
              customerInformationData={customerInformationData}
              updateMyInformation={updateMyInformation}
            />
          </CustomerInformationProfileEditContent>
        );
      case 2:
        return (
          <CustomerInformationProfileEditContent
            title={settingsTitleLabel}
            subTitle={settingsSubTitleLabel}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Wrapper>
        <Content>
          <StyledLinkButton onClick={handleToggleEditMode}>
            <StyledArrowLeftLinkIcon />
            {backLinkLabel}
          </StyledLinkButton>
          <TabList>
            <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
              {accountTabLabel}
            </Tab>
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              {addressTabLabel}
            </Tab>
          </TabList>
          {renderEditItem()}
        </Content>
      </Wrapper>
    </div>
  );
}

const Content = styled('div', {
  position: 'relative',
  my: 7.5,
});

const TabList = styled('ul', {
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  mb: 8,
  p: 0,
  border: '1px solid $borderPrimary',
  '@mediaMaxVerySmall': {
    mx: 4,
  },
  '@mediaMinVerySmall': {
    flexDirection: 'row',
    maxW: 85,
    mx: 'auto',
    h: 8,
  },
});

const Tab = styled('li', {
  fs: 8,
  lineHeight: '32px',
  textAlign: 'center',
  ls: '$ls05',
  opacity: 0.9,
  zIndex: 1,
  flexGrow: 1,
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: 8,
  w: '100%',
  variants: {
    active: {
      true: {
        backgroundColor: '$backgroundSecondary',
        color: '$white',
      },
    },
  },
  '@mediaMinLarge': {
    h: '100%',
  },
});

const Wrapper = styled('div', {
  maxW: '$maxWidthSmall',
  mx: 'auto',
  '@mediaMinLarge': {
    mx: 'auto',
  },
});

const StyledLinkButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  mb: 7,
  '&:hover': {
    textDecoration: 'underline',
  },
  '@mediaMinMedium': {
    mb: 0,
    position: 'absolute',
    t: 0,
    l: 0,
  },
});

const StyledArrowLeftLinkIcon = styled(ArrowIcon, {
  transform: 'rotate(180deg)',
  wh: 5,
  mr: 2,
  pt: 0,
});

export default CustomerInformationProfileEdit;
