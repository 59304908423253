import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import MyPagesPageModel from '../../MyPagesPage/Models/MyPagesPageModel.interface';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { useEffect, useState } from 'react';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import UserState from '../../Shared/Models/UserState.interface';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import OrderInfoViewModel from '../../Order/Models/OrderInfoViewModel.interface';
import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';
import CustomerInformationModel from '../../MyPagesPage/Models/CustomerInformationModel';
import CustomerInformationProfileEdit from '../../CustomerInformation/CustomerInformationProfileEdit';
import {
  GetMyInformation,
  UpdateMyInformation,
} from '../../CustomerInformation/CustomerInformationApi';
import CustomerInformationProfile from '../../CustomerInformation/CustomerInformationProfile';
import { ChangePassword } from '../LoginPage/Account';
import { styled } from '../../stitches.config';
import { H1, H2 } from '../../Atoms/Typography/Headings/Heading';
import { GetOrders } from '../../OrderComponent/Order';
import Orders from '../../OrderComponent/Orders';
import Divider from '../../Atoms/Divider/Divider';

function MyPages() {
  const { fullName } = useUserStateData();
  const { pageIntroText } = useCurrentPage<MyPagesPageModel>();
  const { languageRoute, staticPages, requestToken } = useAppSettingsData();

  const {
    'account/welcome': welcome,
    'customerInfo/myOrders/myOrder': myOrderLabel,
  } = useTranslationData();

  const [editMode, setEditMode] = useState<boolean>(false);
  const handleToggleEditMode = () => {
    setEditMode(!editMode);
    window.scrollTo(0, 0);
  };
  const kexNavigate = useKexNavigate();

  const [user, setUser] = useState<UserState>(useUserStateData());
  const [orderData, setOrderData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentPage = useCurrentPage<PageModelBase>();
  const url = `/api/${
    languageRoute || 'en'
  }/customer/GetMyInformation?customerId=${user.customerId}`;
  const [customerInformation, setCustomerInformation] =
    useState<CustomerInformationModel>();

  const handleUpdateInformation = async (
    data: any,
    addressUpdate?: boolean
  ) => {
    let updatedData = data;

    if (!updatedData.user) {
      updatedData.user = user;
    }

    if (!updatedData.customerInformationData) {
      updatedData.customerInformationData = customerInformation;
    }

    const url = `/api/${languageRoute || 'en'}/customer/${
      addressUpdate ? 'SetMyAddresses' : 'SetMyInformation'
    }`;

    const res = await UpdateMyInformation(
      currentPage.channelId,
      requestToken,
      updatedData,
      url
    );

    setCustomerInformation(res.data.customerInformation);

    if (res.data.user) {
      setUser(res.data.user);
    }

    return res;
  };

  const handleChangePassword = async (
    userCredentials: UserCredentials,
    setError: any,
    setValidationMessage: any
  ) => {
    const res = await ChangePassword(
      userCredentials,
      requestToken,
      languageRoute,
      currentPage.channelId,
      setError,
      setValidationMessage,
      kexNavigate
    );

    return res;
  };

  const fetchData = async () => {
    const customerInformation = await GetMyInformation(
      currentPage.channelId,
      url
    );

    if (customerInformation) {
      setCustomerInformation(customerInformation);
    }
  };

  const fetchOrderData = async () => {
    const url = `/api/${languageRoute || 'en'}/order/GetAllOrders?customerId=${
      user.customerId
    }&pageIndex=1&pageSize=5`;

    const data: OrderInfoViewModel = await GetOrders(
      currentPage.channelId,
      url,
      setIsLoading
    );

    if (data) {
      setOrderData(data);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchData();
      fetchOrderData();
    }
  }, [currentPage.channelId]);

  if (!user.authenticated) {
    kexNavigate(staticPages.loginPage);
  }

  return (
    <main>
      {editMode ? (
        <CustomerInformationProfileEdit
          handleToggleEditMode={handleToggleEditMode}
          customerInformationData={customerInformation}
          user={user}
          updateMyInformation={handleUpdateInformation}
          handleChangePassword={handleChangePassword}
        />
      ) : (
        <>
          <SectionsWrapper>
            <ContentWrapper>
              <TopContent>
                {fullName && (
                  <H1 divider>
                    {welcome}, {fullName}!
                  </H1>
                )}
                {pageIntroText && <Intro>{pageIntroText}</Intro>}
              </TopContent>
            </ContentWrapper>
          </SectionsWrapper>
          <OrdersSection>
            <ContentWrapper>
              <HeadingSection>
                <H2 noMargin>{myOrderLabel}</H2>
              </HeadingSection>
              <StyledDivider />
            </ContentWrapper>
            <Orders isLoading={isLoading} orderData={orderData} />
          </OrdersSection>
          <InformationSection>
            <ContentWrapper>
              <SectionContent>
                <CustomerInformationProfile
                  handleToggleEditMode={handleToggleEditMode}
                  isLoading={isLoading}
                  customerInformationData={customerInformation}
                  user={user}
                />
              </SectionContent>
            </ContentWrapper>
          </InformationSection>
        </>
      )}
    </main>
  );
}

const ContentWrapper = styled('div', {
  maxW: '$contentMaxWidthSmall',
  w: '100%',
  mx: 'auto',
});

const HeadingSection = styled('div', {
  maxW: '100%',
});

const StyledDivider = styled(Divider, {
  w: 16,
  my: 4,
  '@mediaMinLarge': {
    mb: 8,
  },
});

const SectionsWrapper = styled('div', {
  w: '100%',
  backgroundColor: '$backgroundPrimary',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
});

const TopContent = styled('div', {
  maxW: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '@mediaMinLarge': {
    mx: 'auto',
  },
});

const OrdersSection = styled('div', {
  wh: '100%',
  backgroundColor: '$rootBackgroundColor',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
});

const InformationSection = styled('div', {
  w: '100%',
  backgroundColor: '$backgroundPrimary',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
});

const Intro = styled('p', {
  fs: 8,
  fontWeight: '$fw300',
  lineHeight: '24px',
  opacity: 0.9,
  mixBlendMode: 'normal',
  '@mediaMinMedium': {
    fs: 10,
    lineHeight: '32px',
  },
});

const SectionContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxW: '100%',
  '@mediaMinLarge': {
    mx: 'auto',
  },
});

export default MyPages;
