import { getFormattedDate } from '../Shared/Common/Helpers';
import RecurringOrderViewModel from '../Order/Models/RecurringOrderViewModel.interface';
import { GetOrder, AddItemsToCart } from './RecurringOrder';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';
import { useKexNavigate } from '../Kex/KexRouter/KexRouter';
import { AttentionIcon, PreviewIcon } from '../Atoms/Icons';
import { styled } from '../stitches.config';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import KexLink from '../Kex/KexLink';
import { useState } from 'react';

type PropType = {
  order: RecurringOrderViewModel;
};

function RecurringOrderRow({ order }: PropType) {
  const { channelId } = useCurrentPage<PageModelBase>();
  const {
    languageRoute,
    staticPages: { recurringOrderCartPage },
  } = useAppSettingsData();

  const {
    'customerInfo/myOrders/noRecurringOrderName': noRecurringOrderNameLabel,
    'customerInfo/myOrders/nextOrderDate': nextOrderDateLabel,
    'customerInfo/myOrders/updateOrder': updateOrderLabel,
    'customerInfo/myOrders/onHold': onHoldLabel,
  } = useTranslationData();

  const kexNavigate = useKexNavigate();
  const { organizationId } = useUserStateData();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleGetOrder = async () => {
    const url = `/api/${
      languageRoute || 'en'
    }/recurringorder/GetOrder?organizationId=${organizationId}&recurringOrderId=${
      order.id
    }`;

    const data = await GetOrder(channelId, url);

    if (data?.order) {
      await AddItemsToCart(channelId, languageRoute, data.order.items);
    }

    kexNavigate(`${recurringOrderCartPage}?recurringOrderId=${order.id}`);
  };

  return (
    <>
      <ArrayItems
        onClick={handleGetOrder}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <div>
          <IconRounded>
            {order.name ? (
              <PreviewIcon css={IconStyle} />
            ) : (
              <AttentionIcon css={IconStyle} />
            )}
          </IconRounded>
        </div>
        <ArrayItem>
          <Title underline={isHovered}>
            {order.name ?? noRecurringOrderNameLabel}
          </Title>
          <ArrayOrderItem>
            {order.onHold ? (
              <Status>{onHoldLabel}</Status>
            ) : order.name ? (
              <>
                <Status>{nextOrderDateLabel}</Status>
                {`- ${getFormattedDate(order.nextOrderDate)}`}
              </>
            ) : (
              updateOrderLabel
            )}
          </ArrayOrderItem>
        </ArrayItem>
      </ArrayItems>
    </>
  );
}

const IconStyle = {
  wh: 5,
};

const IconRounded = styled('div', {
  mr: 4,
  wh: 8,
  br: 4,
  backgroundColor: '$green',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ArrayItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const ArrayItems = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  my: 5,
});

const Title = styled('span', {
  textAlign: 'left',
  fs: 8,
  fontWeight: '$fw700',
  lineHeight: '28px',
  variants: {
    underline: {
      true: {
        textDecoration: 'underline',
      },
    },
  },
  //letterSpacing: theme.letterSpacing.moreWide,
});

const Status = styled('span', {
  fontWeight: '$fw700',
});

const ArrayOrderItem = styled('span', {
  fs: 7,
  lineHeight: '20px',
  //letterSpacing: theme.letterSpacing.nearWide,
});

export default RecurringOrderRow;
