import Button from '../Atoms/Buttons/Button';
import Divider from '../Atoms/Divider/Divider';
import { H2 } from '../Atoms/Typography/Headings/Heading';
import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel';
import { CheckoutSectionTitle } from '../Pages/Checkout/CheckoutProductListing';
import UserState from '../Shared/Models/UserState.interface';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { styled } from '../stitches.config';
import Select from '../Shared/Input/Select';
import CtaButton from '../Atoms/Buttons/CtaButton';
import { EditIcon } from '../Atoms/Icons';

type PropTypes = {
  onCheckoutPage?: boolean;
  customerInformationData?: CustomerInformationModel;
  user: UserState;
  handleToggleEditMode?: () => any;
  isLoading?: boolean;
  addressFormValue: any;
  setAddressFormValue: (companyAddressId: any) => any;
};

function CustomerInformationProfile({
  onCheckoutPage,
  customerInformationData,
  user,
  handleToggleEditMode,
  isLoading,
  addressFormValue,
  setAddressFormValue,
}: PropTypes) {
  const {
    'customerInfo/deliveryAddress': deliveryAddressLabel,
    'customerInfo/billingAddress': billingAddressLabel,
    'customerInfo/contact': contactLabel,
    'customerInfo/details': detailsLabel,
    'customerInfo/myDetails': myDetailLabel,
    'customerInfo/number': customerInfoNumberLabel,
    'customerInfo/sameAsDelivery': sameAsDeliveryLabel,
    'customerInfo/company': companyLabel,
    'customerInfo/editInformation': editDataLabel,
    'checkoutPage/yourInformation': yourInformationLabel,
  } = useTranslationData();

  return (
    <>
      <CustomerInformationComponent onCheckout={onCheckoutPage}>
        <HeadingSection>
          {onCheckoutPage ? (
            <CheckoutSectionTitle>{yourInformationLabel}</CheckoutSectionTitle>
          ) : (
            <H2 noMargin>{myDetailLabel}</H2>
          )}
          {!onCheckoutPage && <Divider />}
        </HeadingSection>
        <ContentWrapper>
          <ContentItemLeft>
            <Item flex={true}>
              <ListItemTitle>{customerInfoNumberLabel}:&nbsp;</ListItemTitle>
              <ListItem flex={true}>
                {user.externalCustomerId
                  ? user.externalCustomerId
                  : user.customerId}
              </ListItem>
            </Item>

            <Item>
              <div>
                <ListItemTitle>
                  {customerInformationData ? companyLabel : detailsLabel}{' '}
                </ListItemTitle>
              </div>
              <ListItem>
                {customerInformationData?.companyName || user.fullName}
              </ListItem>
            </Item>

            <Item>
              <div>
                <ListItemTitle>{contactLabel}</ListItemTitle>
              </div>
              {customerInformationData && (
                <ListItem>{`${customerInformationData.firstName} ${customerInformationData.lastName}`}</ListItem>
              )}
              {customerInformationData && (
                <ListItem>{customerInformationData.phone}</ListItem>
              )}
              <ListItem>{user.email}</ListItem>
            </Item>

            {!isLoading && !onCheckoutPage && (
              <Item>
                <ButtonContainer>
                  <StyledButton
                    onClick={handleToggleEditMode}
                    type={'secondary'}
                  >
                    <EditIcon color="white" size="xs" />
                    {editDataLabel}
                  </StyledButton>
                </ButtonContainer>
              </Item>
            )}
          </ContentItemLeft>
          {customerInformationData && (
            <ContentItemRight>
              <Item>
                <Address>
                  <ListItemTitle>{deliveryAddressLabel}</ListItemTitle>
                </Address>
                <ListItem>
                  {addressFormValue?.street
                    ? addressFormValue.street
                    : customerInformationData.deliveryAddress?.street}
                </ListItem>
                <ListItem>
                  {addressFormValue?.postalCode || addressFormValue?.city ? (
                    <>
                      {addressFormValue?.postalCode} {addressFormValue.city}
                    </>
                  ) : (
                    <>
                      {customerInformationData.deliveryAddress?.postalCode}{' '}
                      {customerInformationData.deliveryAddress?.city}
                    </>
                  )}
                </ListItem>
              </Item>
              <Item>
                <div>
                  <ListItemTitle>{billingAddressLabel}</ListItemTitle>
                </div>
                {customerInformationData.invoiceAddress?.sameAsDelivery ? (
                  <ListItem>{sameAsDeliveryLabel}</ListItem>
                ) : (
                  <>
                    <ListItem>
                      {customerInformationData.invoiceAddress?.street}
                    </ListItem>
                    <ListItem>
                      {customerInformationData.invoiceAddress?.postalCode}{' '}
                      {customerInformationData.invoiceAddress?.city}
                    </ListItem>
                  </>
                )}
              </Item>
            </ContentItemRight>
          )}
        </ContentWrapper>
      </CustomerInformationComponent>
    </>
  );
}

const ButtonContainer = styled('div', {
  display: 'flex',
  w: 'fit-content',
  mt: 4,
});

const StyledButton = styled(CtaButton, { gap: 2 });

const CustomerInformationComponent = styled('div', {
  backgroundColor: '$backgroundPrimary',
  display: 'flex',
  flexWrap: 'wrap',
  p: 4,
  '@mediaMaxLarge': {
    w: '100%',
    mb: 2,
  },
  '@mediaMinLarge': {
    p: 8,
  },
  variants: {
    onCheckout: {
      true: {
        mb: 4,
        '@mediaMinLarge': {
          mb: 8,
        },
      },
    },
  },
});

const ContentWrapper = styled('div', {
  w: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  '@mediaMinMedium': {
    display: 'flex',
  },
});

const ContentItemLeft = styled('div', {
  flexGrow: 1,
  position: 'relative',
  pb: 11.5,
  w: '100%',
  mr: 2,
  '@mediaMinLarge': {
    w: '50%',
    mr: 0,
  },
  '&:last-child': {
    justifySelf: 'flex-end',
  },
  '@mediaMaxMedium': {
    mr: 0,
  },
});

const ContentItemRight = styled('div', {
  flexGrow: 1,
  position: 'relative',
  pb: 11.5,
  w: '100%',
  mr: 2,
  '@mediaMinLarge': {
    w: '50%',
    mr: 0,
  },
  '&:last-child': {
    justifySelf: 'flex-end',
  },
  '@mediaMaxMedium': {
    mr: 0,
  },
});

const HeadingSection = styled('div', {
  w: '100%',
});

const Item = styled('div', {
  mb: 2.5,
  variants: {
    flex: {
      true: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});

const Address = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledSelect = styled('div', {
  marginBottom: '-16px',
});

const ListItemTitle = styled('p', {
  lineHeight: '28px',
  ls: '$ls08',
  fontWeight: '$fw700',
  fs: 8,
  m: 0,
});

const ListItem = styled('p', {
  lineHeight: '28px',
  ls: '$ls08',
  fs: 8,
  fw: '$fw500',
  m: 0,
  variants: {
    flex: {
      true: {
        marginLeft: 1,
      },
    },
  },
});

export default CustomerInformationProfile;
