import React from 'react';
import Divider from '../Atoms/Divider/Divider';
import { styled } from '../stitches.config';

type PropType = {
  children?: React.ReactNode;
  title: string;
  subTitle?: string;
};

function CustomerInformationProfileEditContent({
  children,
  title,
  subTitle,
}: PropType) {
  return (
    <Root>
      <SectionsWrapper>
        <TopContent>
          <Heading>{title}</Heading>
          {subTitle && (
            <>
              <Divider css={dividerCSS} />
              <Intro>{subTitle}</Intro>
            </>
          )}
        </TopContent>
      </SectionsWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Root>
  );
}

const Root = styled('div', {
  br: 2.5,
  overflow: 'hidden',
  '@mediaMaxLarge': {
    mx: 4,
  },
});

const ContentWrapper = styled('div', {
  width: '100%',
  p: 7.5,
  backgroundColor: '$white',
});

const SectionsWrapper = styled('div', {
  width: '100%',
  px: 7.5,
  backgroundColor: '$backgroundAccent',
});

const TopContent = styled('div', {
  maxWidth: '1024px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$backgroundAccent',
  py: 7.5,
});

const Heading = styled('h1', {
  fs: 13,
  fontWeight: '$fw300',
  lineHeight: '32px',
  ls: '$ls125',
  mb: 0,
});

const dividerCSS = {
  my: 4,
};

const Intro = styled('p', {
  fs: 8,
  my: 0,
  fontWeight: '$fw300',
  lineHeight: '32px',

  ls: '$ls125',
  opacity: 0.9,
  mixBlendMode: 'normal',
  '@mediaMinMedium': {
    fs: 10,
    lineHeight: '32px',
  },
});

export default CustomerInformationProfileEditContent;
