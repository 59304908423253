import RecurringOrderViewModel from '../Order/Models/RecurringOrderViewModel.interface';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import OrderComponent from './OrderComponent';
import RecurringOrderRow from './RecurringOrderRow';

type PropType = {
  orders: RecurringOrderViewModel[];
  ordersCount: number;
  isLoading: boolean;
};

function RecurringOrders({ orders, ordersCount, isLoading }: PropType) {
  const {
    staticPages: { recurringOrdersPage },
  } = useAppSettingsData();

  const {
    'customerInfo/myOrders/recurringOrder': recurringOrderLabel,
    'customerInfo/myOrders/allRecurringOrders': allRecurringOrdersLabel,
  } = useTranslationData();

  return (
    <OrderComponent
      isLoading={isLoading}
      headingLabel={recurringOrderLabel}
      totalOrders={ordersCount}
      showAllLabel={allRecurringOrdersLabel}
      url={recurringOrdersPage}
    >
      {orders.map((order: RecurringOrderViewModel) => (
        <RecurringOrderRow key={order.name} order={order} />
      ))}
    </OrderComponent>
  );
}

export default RecurringOrders;
