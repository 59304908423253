import OrderHistory from './OrderHistory';
import ActiveOrders from './ActiveOrders';
import RecurringOrders from './RecurringOrders';
import { styled } from '../stitches.config';
import Dots from '../Atoms/Loaders/Dots';

type PropType = {
  orderData: any;
  isLoading: boolean;
};

function Orders({ orderData, isLoading }: PropType) {
  return (
    <OrdersWrapper>
      {isLoading ? (
        <DotsWrapper>
          <Dots />
        </DotsWrapper>
      ) : (
        <>
          {orderData?.activeOrders && (
            <ActiveOrders
              ordersCount={orderData.activeOrdersCount}
              isLoading={isLoading}
              orders={orderData.activeOrders}
            />
          )}
          {orderData?.recurringOrders &&
            orderData?.recurringOrders.length > 0 && (
              <RecurringOrders
                ordersCount={orderData.recurringOrdersCount}
                isLoading={isLoading}
                orders={orderData.recurringOrders}
              />
            )}
          {orderData?.orderHistory && (
            <OrderHistory
              ordersCount={orderData.totalOrdersCount}
              isLoading={isLoading}
              orders={orderData.orderHistory}
            />
          )}
        </>
      )}
    </OrdersWrapper>
  );
}

const OrdersWrapper = styled('div', {
  maxWidth: '1440px',
  display: 'grid',
  gridTemplateColumns: '1fr',
  g: 4,
  '@mediaMinLarge': {
    g: 8,
    gridTemplateColumns: 'repeat(3, 1fr)',
    mx: 'auto',
  },
});

const DotsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gridColumnEnd: 'span 3',
  pb: 9,
});

export default Orders;
