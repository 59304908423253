import { useState } from 'react';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';
import CustomerInformationModel from '../../MyPagesPage/Models/CustomerInformationModel';
import UserState from '../../Shared/Models/UserState.interface';
import { styled } from '../../stitches.config';
import {
  CloseIcon,
  EditIcon,
  ExclamationMarkIcon,
  LoadingCircle,
} from '../../Atoms/Icons';
import { InputValidation } from '../../Atoms/Input/InputValidation';
import Input from '../../Atoms/Input/Input';
import Divider from '../../Atoms/Divider/Divider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import CtaButton from '../../Atoms/Buttons/CtaButton';

type PropType = {
  customerInformationData?: CustomerInformationModel;
  user: UserState;
  updateMyInformation: (data: any) => any;
  handleChangePassword: (
    userCredentials: UserCredentials,
    setError: any,
    setValidationMessage: any
  ) => any;
};

function CustomerInformationAccount({
  customerInformationData,
  user,
  updateMyInformation,
  handleChangePassword,
}: PropType) {
  const { languageRoute } = useAppSettingsData();

  const {
    'customerInfo/contact': contactLabel,
    'customerInfo/firstName': firstNameLabel,
    'customerInfo/lastName': lastNameLabel,
    'customerInfo/company': companyLabel,
    'customerInfo/details': detailsLabel,
    'customerInfo/edit': editLabel,
    'customerInfo/cancel': cancelLabel,
    'customerInfo/save': saveLabel,
    'customerInfo/password': passwordLabel,
    'account/email': emailLabel,
    'account/phone': phoneLabel,
    'customerInfo/currentPassword': currentPasswordLabel,
    'customerInfo/newPassword': newPasswordLabel,
    'customerInfo/newPasswordConfirm': newPasswordConfirmLabel,
    'customerInfo/validation/email': emailValidationLabel,
    'validation/invalidPhoneNr': invalidPhoneNrLabel,
    'customerInfo/changeEmailText': changeEmailText,
  } = useTranslationData();

  let copyData = JSON.parse(JSON.stringify(customerInformationData));

  const [userToEdit, setUserToEdit] = useState(user);
  const [customerInformationDataToEdit, setCustomerInformationDataToEdit] =
    useState(copyData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState(-1);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });
  const phoneRegExp = new RegExp('(^[\\s-+()0-9]{6,16}$)');

  const resetData = (cancel?: boolean) => {
    if (cancel) {
      setUserToEdit(user);
      setCustomerInformationDataToEdit(customerInformationData);
    }
    setValidationMessage('');
    setIsLoading(false);
    setError(false);
    setSuccess(false);
    setBtnEnabled(false);
    setPassword({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
    setEditing(-1);
  };

  const handeSetPassword = (name: string, value: string) => {
    const data = { ...password } as any;
    data[name] = value;

    setPassword(data);
  };

  const handleSetEditing = (value: number) => {
    if (value === -1) {
      resetData(true);
    }
    setEditing(value);
  };

  const setName = (name: 'firstName' | 'lastName', value: string) => {
    const data = {
      ...customerInformationDataToEdit,
    } as CustomerInformationModel;
    data[name] = value;

    setCustomerInformationDataToEdit(data);
  };

  const setEmail = (value: string) => {
    const data = {
      ...userToEdit,
    } as UserState;
    data.email = value;

    setUserToEdit(data);
  };

  const setPhone = (value: string) => {
    const data = {
      ...customerInformationData,
    } as CustomerInformationModel;
    data.phone = value;

    setCustomerInformationDataToEdit(data);
  };

  const handleUpdatePassword = async () => {
    if (disabled) return;
    const userCredentials = {
      userName: user.userName,
      email: user.email,
      password: password.currentPassword,
      language: languageRoute,
      newPassword: password.newPassword,
    };

    setIsLoading(true);
    setDisabled(true);
    const res = await handleChangePassword(
      userCredentials,
      setError,
      setValidationMessage
    );

    if (res?.statusCode === 200) {
      setError(false);
      setValidationMessage(res.message);
      setSuccess(true);
    }

    setIsLoading(false);
    setDisabled(false);
  };

  const resetPwdButtonDisabled =
    !!!password.currentPassword ||
    !!!password.newPassword ||
    !!!password.newPasswordConfirm ||
    password.newPassword !== password.newPasswordConfirm;

  const handleSubmit = async () => {
    if (disabled) return;

    const data = {
      customerInformation: customerInformationDataToEdit,
      user: userToEdit,
    };

    setIsLoading(true);
    setDisabled(true);
    setError(false);
    setValidationMessage('');

    const res = await updateMyInformation(data);
    if (res.status !== 200) {
      setError(true);
      setValidationMessage(res.message);
    }

    setIsLoading(false);
    setDisabled(false);

    resetData();
  };

  const handleRenderListItemTitle = (
    title: string,
    editNum: number,
    editable?: boolean
  ) => (
    <ListItemTitle>
      {title}
      {editable && (
        <StyledButton
          onClick={() => handleSetEditing(editing === editNum ? -1 : editNum)}
          disabled={disabled}
        >
          {editing === editNum ? (
            <StyledCloseIcon size={'xs'} />
          ) : (
            <StyledEditIcon />
          )}
          <span>{editing === editNum ? cancelLabel : editLabel}</span>
        </StyledButton>
      )}
    </ListItemTitle>
  );

  return (
    <>
      <Item>
        {handleRenderListItemTitle(
          customerInformationData ? companyLabel : detailsLabel,
          0
        )}
        <ListItem>
          <ListItemText>
            {customerInformationDataToEdit?.companyName}
          </ListItemText>
          <ListItemText>{user.customerId}</ListItemText>
        </ListItem>
      </Item>
      <Divider type={'max'} css={dividerCSS} />
      <Item>
        {handleRenderListItemTitle(contactLabel, 1, true)}
        <ListItem>
          {editing === 1 ? (
            <>
              {error && (
                <ValidationMessage>
                  <ExclamationMarkIcon css={IconStyleError} />
                  <ErrorMessage>{validationMessage}</ErrorMessage>
                </ValidationMessage>
              )}
              <InputValidation>
                <Input
                  type="text"
                  name="firstName"
                  title={firstNameLabel}
                  placeholder={customerInformationDataToEdit.firstName}
                  onChange={(value: string) => setName('firstName', value)}
                  onKeyDown={(e: any) => e.key === 'Enter' && handleSubmit()}
                />
                <Input
                  type="text"
                  name="lastName"
                  title={lastNameLabel}
                  placeholder={customerInformationDataToEdit.lastName}
                  onChange={(value: string) => setName('lastName', value)}
                  onKeyDown={(e: any) => e.key === 'Enter' && handleSubmit()}
                />
                <Input
                  type="phone"
                  name="Phone"
                  title={phoneLabel}
                  placeholder={customerInformationDataToEdit.phone}
                  onChange={setPhone}
                  onKeyDown={(e: any) => e.key === 'Enter' && handleSubmit()}
                  validation={{
                    pattern: phoneRegExp,
                    errorMessage: invalidPhoneNrLabel,
                  }}
                />
                <Input
                  type="email"
                  name="Email"
                  title={emailLabel}
                  placeholder={userToEdit.email}
                  onChange={setEmail}
                  onKeyDown={(e: any) => e.key === 'Enter' && handleSubmit()}
                  validation={{
                    pattern:
                      /(?=^.{1,241}$)^([\w.-]+)@([\w-]+)((\.(\w){2,})+)$/,
                    errorMessage: emailValidationLabel,
                  }}
                  disabled={true}
                />
                {changeEmailText && (
                  <ChangeEmailText>{changeEmailText}</ChangeEmailText>
                )}
              </InputValidation>
              <ButtonContainer>
                <StyledCtaButton
                  type="secondary"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  {saveLabel}
                </StyledCtaButton>
              </ButtonContainer>
            </>
          ) : (
            <>
              <ListItemText>{`${customerInformationDataToEdit.firstName} ${customerInformationDataToEdit.lastName}`}</ListItemText>
              <ListItemText>{customerInformationDataToEdit.phone}</ListItemText>
              <ListItemText>{userToEdit.email}</ListItemText>
            </>
          )}
        </ListItem>
      </Item>
      <Divider type={'max'} css={dividerCSS} />
      <Item>
        {handleRenderListItemTitle(passwordLabel, 2, true)}
        <ListItem>
          {editing === 2 ? (
            <>
              {error && (
                <ValidationMessage>
                  <ExclamationMarkIcon css={IconStyleError} color={'white'} />
                  <ErrorMessage>{validationMessage}</ErrorMessage>
                </ValidationMessage>
              )}
              {success && (
                <ValidationMessage success>
                  <ExclamationMarkIcon css={IconStyleError} color={'white'} />
                  <ErrorMessage>{validationMessage}</ErrorMessage>
                </ValidationMessage>
              )}
              <InputValidation>
                <Input
                  type="password"
                  name="Password"
                  title={currentPasswordLabel}
                  placeholder={password.currentPassword}
                  onChange={(value: string) =>
                    handeSetPassword('currentPassword', value)
                  }
                  onKeyDown={
                    !resetPwdButtonDisabled
                      ? (e: any) => e.key === 'Enter' && handleUpdatePassword()
                      : undefined
                  }
                />
                <Input
                  type="password"
                  name="NewPassword"
                  title={newPasswordLabel}
                  placeholder={password.newPassword}
                  onChange={(value: string) =>
                    handeSetPassword('newPassword', value)
                  }
                  onKeyDown={
                    !resetPwdButtonDisabled
                      ? (e: any) => e.key === 'Enter' && handleUpdatePassword()
                      : undefined
                  }
                />
                <Input
                  type="password"
                  name="NewPasswordConfirm"
                  title={newPasswordConfirmLabel}
                  placeholder={password.newPasswordConfirm}
                  onChange={(value: string) =>
                    handeSetPassword('newPasswordConfirm', value)
                  }
                  onKeyDown={
                    !resetPwdButtonDisabled
                      ? (e: any) => e.key === 'Enter' && handleUpdatePassword()
                      : undefined
                  }
                />
              </InputValidation>
              <ButtonContainer>
                <StyledCtaButton
                  type="secondary"
                  onClick={handleUpdatePassword}
                  isLoading={isLoading}
                  disabled={resetPwdButtonDisabled}
                >
                  {saveLabel}
                </StyledCtaButton>
              </ButtonContainer>
            </>
          ) : (
            <PasswordArea>
              <StyledOval />
              <StyledOval />
              <StyledOval />
              <StyledOval />
              <StyledOval />
              <StyledOval />
              <StyledOval />
              <StyledOval />
              <StyledOval />
              <StyledOval />
            </PasswordArea>
          )}
        </ListItem>
      </Item>
    </>
  );
}

const ErrorMessage = styled('p', {
  color: '$white',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
});

const ChangeEmailText = styled('p', {
  fs: 6,
  mt: 0,
  mb: 5,
  '@mediaMinLarge': {
    fs: 7,
  },
});

const ValidationMessage = styled('div', {
  fs: 8,
  backgroundColor: '$errorPrimary',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
  alignItems: 'center',
  variants: {
    success: {
      true: {
        backgroundColor: '$successBackground',
      },
    },
  },
});

const IconStyleError = {
  wh: 4,
  mr: 2,
};

const PasswordArea = styled('div', {
  display: 'flex',
});

const StyledOval = styled('div', {
  mx: 0.5,
  wh: 2,
  br: '50%',
  backgroundColor: '$ovalBackgroundPrimary',
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: '$white',
  h: 4,
  m: 'auto',
});

const StyledCtaButton = styled(CtaButton, {
  // fs: 7,
  // fontWeight: '$fw400',
  // letterSpacing: '$ls1',
  // backgroundColor: '$buttonPrimary',
  // color: '$white',
  // float: 'right',
  // br: 'none',
  // ml: 'auto',
  // p: 4,
  // transition: 'ease-in-out 0.3s',
  // '&:disabled': {
  //   backgroundColor: '$buttonTabButtonInActive',
  // },
});

const ButtonContainer = styled('div', {
  display: 'flex',
  my: 2,
  pb: 4,
});

const ListItemText = styled('p', {
  fs: 8,
  lineHeight: '28px',
});

const dividerCSS = {
  mx: 0,
};

const StyledButton = styled('button', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  px: 1,
});

const IconStyle = {
  wh: 3.5,
  mr: 1.25,
};

const StyledCloseIcon = styled(CloseIcon, {
  ...IconStyle,
});

const StyledEditIcon = styled(EditIcon, {
  ...IconStyle,
});

const Item = styled('div', {});

const ListItem = styled('div', {
  maxW: 100,
  lineHeight: '28px',
  fs: 8,
});

const ListItemTitle = styled('div', {
  mb: 2.5,
  lineHeight: '28px',
  fs: 8,
  fontWeight: '$fw700',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export default CustomerInformationAccount;
